import { getCookie } from "./cookies";

export const baseUrl = process.env.NEXT_PUBLIC_API_BASE;
export const IMAGE_BASE = process.env.NEXT_PUBLIC_IMAGE_BASE;
export const publicUrl = process.env.NEXT_PUBLIC_URL;

export const options = {
  "Content-Type": "application/json",
};

export const fetcher = async ({
  url,
  headers,
}: {
  url: string;
  headers: HeadersInit | undefined;
}) => {
  try {
    const cookie = getCookie("seller_ref_id");
    const res = await fetch(`/api${url}`, {
      method: "GET",
      headers: { ...headers, "seller-ref-id": cookie as string },
    });
    const response = await res.json();
    if (response.success) {
      return response;
    }
    if (response.success == false) {
      const error = response;
      if (error?.msg && error?.msg === "JsonWebTokenError") {
        // network error
      } else if (error && error?.msg === "Session Expired") {
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.clear();
        setTimeout(() => {
          window.location.pathname !== "/user/account" &&
            window.location.replace(`/user/account`);
        }, 500);
        throw new Error("Session Expired");

        // session expire error
      } else {
        throw error;
      }
    }
  } catch (error) {
    return error;
  }
};

export const fetchData = async (
  url: string,
  headers: HeadersInit | undefined
) => {
  const res = await fetch(`${baseUrl}api${url}`, {
    headers: {
      ...headers,
    },
    cache: "no-store",
  }).then((res) => res.json());
  return res;
};

export const postRequest = async ({
  url,
  body,
}: {
  url: string;
  body: any;
}) => {
  try {
    const seller_ref_id = getCookie("seller_ref_id");
    const nodeENV = process.env.NODE_ENV;
    const res = await fetch(
      `${nodeENV === "development" ? "http" : "https"}://${
        window.location.hostname
      }/api/${url}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "seller-ref-id": seller_ref_id as string,
        },
        body: JSON.stringify(body),
      }
    );
    const response = await res.json();
    if (response.success) {
      return response;
    }
    if (response.success == false) {
      const error = response;
      if (error?.msg && error?.msg === "JsonWebTokenError") {
        // network error
      } else if (error && error?.msg === "Session Expired") {
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.clear();
        setTimeout(() => {
          window.location.pathname !== "/user/account" &&
            window.location.replace(`/user/account`);
        }, 500);
        throw new Error("Session Expired");

        // session expire error
      } else {
        throw error;
      }
    }
  } catch (error) {
    return error;
  }
};
