import React from "react";
import { IMAGE_BASE } from "@/utils/Api";
import { Image } from "./types";

const ImageComponent: React.FC<Image> = ({
  imgObj,
  size,
  height,
  width,
  alt,
  className,
}) => {
  let src = "";
  if (imgObj && `path` in imgObj) {
    if (size) {
      src = `${IMAGE_BASE}${imgObj.path.replace("public", `public/${size}`)}`;
    } else {
      src = `${IMAGE_BASE}${imgObj.path}`;
    }
  }
  return <img src={src} alt={alt} className={className || ""} />;
};

export default ImageComponent;
