"use client";

import { sectionLayout } from "@/app/(app)/types";
import ImageComponent from "@/components/Image";
import { CURRENCY_SIGN } from "@/utils/constans";
import { commaNumber } from "@/utils/helpers";
import Link from "next/link";
import { FC } from "react";

const CardSampleFour: FC<{
  product: sectionLayout["data"]["section"][0]["product_section"]["product"][0];
  setDangerously?: boolean;
  productIndex: number;
}> = ({ product, setDangerously, productIndex }) => {
  return (
    <>
      <div className="relative">
        <Link prefetch={false} href={`/product/${product.url_key}`}>
          <div className="relative flex justify-center rounded-xl aspect-square mt-1">
            <ImageComponent
              imgObj={product.image[0].document}
              size={"200-200"}
              className="w-[264px] h-[264px] object-cover"
            />
          </div>
        </Link>
        {product.variant[0].price - product.variant[0].sales_price > 0 && (
          <div className="absolute top-0 right-0 bg-secondary font-bold text-white leading-tight text-xs flex gap-1 items-center justify-center text-center p-2">
            <span>
              {Math.round(
                ((product.variant[0].price - product.variant[0].sales_price) /
                  product.variant[0].price) *
                  100
              ).toFixed(0)}
              %
            </span>
            <span>OFF</span>
          </div>
        )}
        <div className="mt-2 p-1">
          {setDangerously ? (
            <Link
              className="hover:text-secondary"
              prefetch={false}
              href={`/product/${product.url_key}`}
              key={product._id + productIndex}
            >
              <div dangerouslySetInnerHTML={{ __html: product.name || "" }} />
            </Link>
          ) : (
            <Link prefetch={false} href={`/product/${product.url_key}`}>
              <div className="line-clamp-2 hover:underline text-base mb-1 h-10 overflow-hidden text-ellipsis font-normal leading-5 text-gray-800 hover:text-secondary">
                {product.name}
              </div>
            </Link>
          )}
          {product && product.variant.length > 0 && (
            <>
              {product.is_multi_variant_image ? (
                <>
                  {product.max_sales_price - product.min_sales_price === 0 ? (
                    <div className="font-semibold text-black text-base">
                      {CURRENCY_SIGN}
                      {commaNumber(product.max_sales_price || 0)}
                    </div>
                  ) : (
                    <div className="flex flex-wrap items-center justify-center">
                      <div className="font-semibold text-black text-base">
                        {CURRENCY_SIGN}
                        {commaNumber(product.min_sales_price)}
                      </div>
                      <span className="mx-1 font-semibold text-black text-base">
                        -
                      </span>
                      <div className="font-semibold text-black text-base">
                        {CURRENCY_SIGN}
                        {commaNumber(product.max_sales_price)}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {product.variant[0].price - product.variant[0].sales_price >
                  0 ? (
                    <div className="flex items-end gap-2">
                      <div className="font-semibold text-black text-base">
                        {CURRENCY_SIGN}
                        {commaNumber(product.variant[0].sales_price || 0)}
                      </div>
                      <div className="mr-2 font-medium text-gray-500 line-through text-xs mb-0.5">
                        {CURRENCY_SIGN}
                        {commaNumber(product.variant[0].price) || 0}
                      </div>
                    </div>
                  ) : (
                    <div className="font-bold text-black text-base">
                      {CURRENCY_SIGN}
                      {commaNumber(product.variant[0].sales_price || 0)}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <button className="bg-primary text-white py-2 px-4 rounded-md mt-2 w-full">
            Add to Cart
          </button>
        </div>
      </div>
    </>
  );
};

export default CardSampleFour;
