import Logo from "../assets/img/logo.png";
import NoImageFound from "../assets/img/No_Image_Available.jpg";
import LogoIcon from "../assets/img/logo-icon.png";

export const APP_LOGO = Logo;
export const APP_NAME = "Web Pasal";
export const PHONE_NO = "9801865567";
export const LOGO_ICON = LogoIcon;
export const COUNTRY_CODE = "+977";
export const GOOGLE_TAG_MANAGER = "GTM-P6RW3PCP";

export const GOOGLE_CLIENT_ID =
  "496781802768-bdsg2sa0ovc1lo0hnksphqtcsv75t9f3.apps.googleusercontent.com";
export const GOOOGLE_SECRET = "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";
export const CURRENCY_SIGN = "Rs.";
export const INTL_NUMBER_FORMAT = "en-IN"; // en-US
export const NO_IMAGE = NoImageFound;
export const DATE_FORMAT = "YYYY-MM-DD";
export const SHOW_REFERAL_CODE_INPUT = false;
export const RECAPTCHA_SITE_KEY = "6LejJ5YpAAAAAB9RPNFRQrCTGtn9_rBC65MkAFlu";

export const URL_BASE =
  process.env.NODE_ENV === "production"
    ? "https://www.webpasal.com"
    : "http://localhost:6005";

// product sizes
export const CAT_PRODUCT_SIZE = 40;
export const SEARCH_PRODUCT_SIZE = 20;
export const BRAND_PRODUCT_SIZE = 20;
export const PAGE_LAYOUT_SIZE = 5;
export const LAYOUT_SECTION_SIZE = 4;

// category & search sorting options
export const sortOptions = [
  { label: "Default", value: "default" },
  { label: "Price Low to High", value: "price-low-to-high" },
  { label: "Price High to Low", value: "price-high-to-low" },
  // { label: "Rating High to Low", value: "rating-high-to-low" },
  // { label: "Rating Low to High", value: "rating-low-to-high" },
];

// category product card type
export const CAT_CARD_ONE = true; // make it true to use card one in category page
// category product card type
export const SEARCH_CARD_ONE = false; // make it true to use card one in category page

//No. of blogs to display per page
export const BLOG_COUNT_PER_PAGE = 5;

//SEO
export const MAIN_APP_DESC = "Web Pasal Create Your E-commerce in few moment.";

// Seller Dashboard Domain
export const SELLER_DASHBOARD_DOMAIN =
  process.env.NODE_ENV === "production"
    ? "https://app.webpasal.com"
    : "http://localhost:6022";
