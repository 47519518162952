import { IMAGE_BASE } from "./Api";
import { INTL_NUMBER_FORMAT, NO_IMAGE } from "./constans";

export const errorBuildLvl1: any = (
  errors: Array<{ context: any; message: any }>
) => {
  return (
    errors &&
    errors.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.context.key]: curr.message.replace(/[/"_]/g, " "),
      }),
      {}
    )
  );
};

export const queryHelper = (queryobj: any) => {
  let query = "";
  if (queryobj) {
    Object.keys(queryobj).map((each, index) => {
      if (index === 0) {
        query = `${each}=${queryobj[each]}`;
      } else {
        query = `${query}&${each}=${queryobj[each]}`;
      }
      return null;
    });
  }
  return query;
};

export const commaNumber = (number: number) =>
  new Intl.NumberFormat(
    INTL_NUMBER_FORMAT
    // {
    //   maximumSignificantDigits: 3,
    // }
  ).format(number);

export const returnImgSrc = (imgObj: { path: string }, size?: string) => {
  if (imgObj && `path` in imgObj) {
    if (size) {
      return `${IMAGE_BASE}${imgObj.path.replace("public", `public/${size}`)}`;
    }
    return `${IMAGE_BASE}${imgObj.path}`;
  } else {
    return "/No_Image_Available.jpg";
  }
};

export const setDefaultsForFirstAddress = (address?: any, addresses?: any) => {
  if (addresses.length === 0) {
    return {
      ...address,
      is_default_shipping: true,
      is_default_billing: true,
    };
  }

  return address;
};

export const isOtherDefaultAddress = (
  addresses?: any,
  currentAddressId?: string | null
) => {
  return addresses.some(
    (address) =>
      (address.is_default_shipping || address.is_default_billing) &&
      address._id !== currentAddressId
  );
};
